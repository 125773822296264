/* eslint-disable import/prefer-default-export */
import router from '@/router'
import { currencyConvert } from '@/util/functions'
import { kFormatter } from '@core/utils/filter'
import themeConfig from '@themeConfig'

const $themeColors = themeConfig.themes.light
const undefined = `marketShare-${new Date().toDateString()}`

export const MfrMarketSharePieChartOptions = {
  title: {
    text: 'Manufacturer Market Share Pie Chart',

    // verticalAlign: 'middle',
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: $themeColors.primary,
    },
  },
  legend: {
    show: true,
  },
  dataLabels: {
    enabled: true,
    offsetX: 30,
    style: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
    formatter(value) {
      return `%${parseInt(value * 100, 10) / 100}`
    },
  },
  labels: [],
  chart: {
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ',',
          headerCategory: 'category',
          headerValue: 'value',
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString()
          },
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
      autoSelected: 'zoom',
    },
    type: 'donut',
    sparkline: {
      enabled: false,
    },
    events: {
      dataPointSelection: (event, chartContext, config) => {
        router.app.$root.$emit('mfr-market-share-pie-chart', config)
      },
    },
  },
  noData: {
    text: 'No Data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: $themeColors.primary,
      fontSize: '20px',

      //   fontFamily: undefined,
    },
  },
  plotOptions: {
    pie: {
      donut: {
        expandOnClick: false,
        size: '70%',
        labels: {
          show: true,
          name: {
            fontSize: '15px',
          },
          value: {
            show: true,
            fontSize: '15px',
            fontWeight: 600,
            formatter(value) {
              return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
            },
          },
          total: {
            show: true,
            label: 'Drug Costs',
            color: 'rgba(94, 86, 105, 0.68)',
            formatter(value) {
              return `${currencyConvert(
                parseInt(value.globals.seriesTotals.reduce((total, num) => total + num) * 100, 10) / 100,
              )}`
            },
          },
        },
      },
    },
  },
  states: {
    active: {
      allowMultipleDataPointsSelection: true,
      filter: {
        type: 'darken',
        value: 0.35,
      },
    },
  },
}

export const MfrMarketSharePieChartWatchListOptions = {
  title: {
    text: 'Manufacturer Market Share',

    // verticalAlign: 'middle',
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: $themeColors.primary,
    },
  },
  legend: {
    show: true,
  },
  dataLabels: {
    enabled: true,
    offsetX: 30,
    style: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
    formatter(value) {
      return `%${parseInt(value * 100, 10) / 100}`
    },
  },
  labels: [],
  chart: {
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ',',
          headerCategory: 'category',
          headerValue: 'value',
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString()
          },
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
      autoSelected: 'zoom',
    },
    type: 'donut',
    sparkline: {
      enabled: false,
    },
    events: {
      dataPointSelection: (event, chartContext, config) => {
        router.app.$root.$emit('mfr-market-share-pie-chart', config)
      },
    },
  },
  noData: {
    text: 'No Data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: $themeColors.primary,
      fontSize: '20px',

      //   fontFamily: undefined,
    },
  },
  plotOptions: {
    pie: {
      donut: {
        expandOnClick: false,
        size: '70%',
        labels: {
          show: true,
          name: {
            fontSize: '15px',
          },
          value: {
            show: true,
            fontSize: '15px',
            fontWeight: 600,
            formatter(value) {
              return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
            },
          },
          total: {
            show: true,
            label: 'Drug Costs',
            color: 'rgba(94, 86, 105, 0.68)',
            formatter(value) {
              return `${currencyConvert(
                parseInt(value.globals.seriesTotals.reduce((total, num) => total + num) * 100, 10) / 100,
              )}`
            },
          },
        },
      },
    },
  },
  states: {
    active: {
      allowMultipleDataPointsSelection: true,
      filter: {
        type: 'darken',
        value: 0.35,
      },
    },
  },
}

export const TotalQtyPerPharmacyPieOptions = {
  title: {
    text: 'Qty Dispensed',

    // verticalAlign: 'middle',
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: $themeColors.primary,
    },
  },
  legend: {
    show: true,
  },
  dataLabels: {
    enabled: true,
    offsetX: 30,
    style: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
    formatter(value) {
      return `%${parseInt(value * 100, 10) / 100}`
    },
  },
  labels: [],
  chart: {
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ',',
          headerCategory: 'category',
          headerValue: 'value',
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString()
          },
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
      autoSelected: 'zoom',
    },
    type: 'donut',
    sparkline: {
      enabled: false,
    },
    events: {
      dataPointSelection: (event, chartContext, config) => {
        router.app.$root.$emit('mfr-market-share-pie-chart', config)
      },
    },
  },
  noData: {
    text: 'No Data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: $themeColors.primary,
      fontSize: '20px',

      //   fontFamily: undefined,
    },
  },
  plotOptions: {
    pie: {
      donut: {
        expandOnClick: false,
        size: '70%',
        labels: {
          show: true,
          name: {
            fontSize: '15px',
          },
          value: {
            show: true,
            fontSize: '15px',
            fontWeight: 600,
            formatter(value) {
              return `${parseInt(value * 100, 10) / 100}`
            },
          },
          total: {
            show: true,
            label: 'Total Dispensed',
            color: 'rgba(94, 86, 105, 0.68)',
            formatter(value) {
              return `${parseInt(value.globals.seriesTotals.reduce((total, num) => total + num) * 100, 10) / 100}`
            },
          },
        },
      },
    },
  },
  states: {
    active: {
      allowMultipleDataPointsSelection: true,
      filter: {
        type: 'darken',
        value: 0.35,
      },
    },
  },
}

export const mfrHeatMapOptions = {
  chart: {
    height: 450,
    type: 'heatmap',
  },
  dataLabels: {
    enabled: false,
  },

  //   colors,
  xaxis: {
    type: 'category',
    categories: [],
  },

  //   title: {
  //     text: 'Manufacturer HeatMap Chart (Different color shades for each series)',
  //   },
  grid: {
    padding: {
      right: 20,
    },
  },
  plotOptions: {
    heatmap: {
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 5000,
            color: $themeColors.error,
            name: 'low',
          },
          {
            from: 5001,
            to: 15000,
            color: '#128FD9',
            name: 'medium',
          },
          {
            from: 15001,
            to: 100000,
            color: $themeColors.success,
            name: 'high',
          },
        ],
      },
    },
  },
}

export const mfrStackedChartOptions = {
  chart: {
    type: 'bar',

    // height: 350,
    stacked: true,
    stackType: '100%',
  },

  //   responsive: [{
  //     breakpoint: 480,
  //     options: {
  //       legend: {
  //         position: 'bottom',
  //         offsetX: -10,
  //         offsetY: 0,
  //       },
  //     },
  //   }],
  xaxis: {
    categories: [''],
  },

  //   fill: {
  //     opacity: 1,
  //   },
  legend: {
    position: 'right',
    offsetX: 0,
    offsetY: 50,
  },
}

export const lineChartPerMoleculeOptions = {
  chart: {
    height: 350,
    type: 'line',
    dropShadow: {
      enabled: true,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: 'smooth',
  },
  title: {
    text: 'Patients per Month',
    align: 'left',
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  markers: {
    size: 1,
  },
  xaxis: {
    categories: [],
    title: {
      text: 'Month',
    },
  },
  yaxis: {
    title: {
      text: 'Number of Patients',
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    floating: true,
    offsetY: -25,
    offsetX: -5,
  },
}

export const marketShareColumnChartOptions = {
  chart: {
    type: 'bar',
    height: 600,
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 1,
      horizontal: false,

      //   columnWidth: '35%',
      //   startingShape: 'rounded',
      //   endingShape: 'rounded',
    },
  },
  xaxis: {
    categories: [],
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      formatter: value => kFormatter(value, 0),
    },
  },
  grid: {
    strokeDashArray: 1,
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: true,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
    lineCap: 'round',
    colors: ['#fff'],
  },
  responsive: [
    {
      breakpoint: 1400,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '30%',
          },
        },
      },
    },
  ],
}

export const mfrTreeMapOptions = {
  tooltip: {
    enabled: true,
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '50px',
      fontWeight: 'bold',
    },
  },
  legend: {
    show: false,
  },
  chart: {
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ',',
          headerCategory: 'category',
          headerValue: 'value',
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString()
          },
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
      autoSelected: 'zoom',
    },
    height: 350,
    type: 'treemap',
    events: {
      dataPointSelection: (event, chartContext, config) => {
        router.app.$root.$emit('filter-molecule', config)
      },
    },
  },
  colors: [
    '#3B93A5',
    '#F7B844',
    '#ADD8C7',
    '#EC3C65',
    '#CDD7B6',
    '#C1F666',
    '#D43F97',
    '#1E5D8C',
    '#421243',
    '#7F94B0',
    '#EF6537',
    '#C0ADDB',
  ],
  plotOptions: {
    treemap: {
      enableShades: true,
      shadeIntensity: 0.7,
      reverseNegativeShade: true,
      distributed: false,
      useFillColorAsStroke: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
            foreColor: undefined,
            name: undefined,
          },
        ],
        inverse: false,
        min: undefined,
        max: undefined,
      },
    },
  },
}
